<template>
  <div class="accordion">
    <div
      v-for="item in items"
      :key="item.id"
      class="accordion-item"
      :class="{ 'accordion-item--active': item.id === active }"
      @click="setCurrentItem(item.id)"
    >
      <Pane>
        <div class="accordion-item__head row">
          <h3 class="col-11">{{ item.title }}</h3>
          <div class="col-1"><button class="accordion-item__carret" /></div>
        </div>
        <div class="accordion-item__body row">
          <div class="col-12" v-html="item.content" />
        </div>
      </Pane>
    </div>
  </div>
</template>

<script>
import Pane from '@/components/decorative/Pane.vue';

export default {
  name: 'Accordion',
  props: {
    items: Array,
    active: Number,
  },
  components: {
    Pane,
  },
  methods: {
    setCurrentItem(id) {
      if (id !== this.active) this.$emit('update:active', id);
      else if (id === this.active) this.$emit('update:active', '');
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";
.accordion {
  &-item {
    &__head {
      font-weight: 500;
      line-height: 1.26;

      h3 {
        font-size: 14px;
      }
    }

    &__carret {
      display: block;
      width: 10px;
      height: 10px;
      border: none;
      background-image: url("/icons/carret.svg");
      background-color: $c-white;
      background-position: center;
      background-repeat: no-repeat;
      transform-origin: center;
    }

    &__body {
      display: block;
      line-height: 1.25;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition: height 0ms 400ms, opacity 400ms 0ms;

      ul {
        list-style: inside;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &--active {
      .accordion-item__carret {
        transform: rotate(180deg);
      }

      .accordion-item__body {
        height: auto;
        padding-top: 20px;
        opacity: 1;
        transition: height 0ms 400ms, opacity 600ms 0ms;
      }
    }

    &:not(&--active) {
      cursor: pointer;
    }

    & + & {
      margin-top: 20px;
    }
  }
}
</style>
