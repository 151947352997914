<template>
  <div class="help-page">
    <Accordion :active.sync="active" :items="helps" />
  </div>
</template>

<script>
import Accordion from '@/components/decorative/Accordion.vue';

export default {
  name: 'Help',
  components: {
    Accordion,
  },
  computed: {
    active: {
      get() {
        return +this.$route.query.active;
      },
      set(newVal) {
        if (newVal !== this.active) {
          this.$router.replace({ query: { active: newVal.toString() } });
        }
      },
    },
    helps() {
      const link1 = '<a href="skype:live:partners_531?chat">UnionTraff</a>';
      const link2 = `<a href="https://utraff.com/#contact">${this.$t(
        'main.viaFeedbackForm',
      )}</a>`;
      const link3 = '<a href="http://utraff.com/static/pdf/rules.pdf" target="_blank" rel="noreferrer noopenner">http://utraff.com/static/pdf/rules.pdf</a>';
      return [
        {
          id: 1,
          title: this.$t('faq.title1'),
          content: this.$t('faq.answer1'),
        },
        {
          id: 2,
          title: this.$t('faq.title2'),
          content: this.$t('faq.answer2', [link1, link2]),
        },
        {
          id: 3,
          title: this.$t('faq.title3'),
          content: this.$t('faq.answer3', [link3]),
        },
        {
          id: 4,
          title: this.$t('faq.title4'),
          content: this.$t('faq.answer4'),
        },
        {
          id: 5,
          title: this.$t('faq.title5'),
          content: this.$t('faq.answer5'),
        },
        {
          id: 6,
          title: this.$t('faq.title6'),
          content: this.$t('faq.answer6'),
        },
        {
          id: 7,
          title: this.$t('faq.title7'),
          content: this.$t('faq.answer7'),
        },
        {
          id: 8,
          title: this.$t('faq.title8'),
          content: this.$t('faq.answer8'),
        },
        {
          id: 9,
          title: this.$t('faq.title9'),
          content: this.$t('faq.answer9'),
        },
      ];
    },
  },
};
</script>
